import React from "react";
import styled from "styled-components";
import { Flex } from "../Header/Header";
import Card from "./Card";
const Content = styled.div`
  padding: 70px 0px 150px 0px;
`;
const Box = styled(Flex)`
  flex-direction: column;
  gap: 60px;
`;
const Title = styled.p`
  color: #242424;
  text-align: right;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Open Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 83.2px */
  letter-spacing: 1.92px;
  text-transform: capitalize;
`;
const Cards = styled(Flex)`
  align-items: flex-start;
  row-gap: 35px;
  column-gap: 40px;
  width: 100%;
  flex-wrap: wrap;
`;
const MetodsPage = () => {
  return (
    <Content>
      <div className="container">
        <Box>
          <Title>Metodlar</Title>
          <Cards>
            <Card text={"Loyxalar metodi"} />
            <Card text={"Loyxalar metodi"} />
            <Card text={"Loyxalar metodi"} />
            <Card text={"Loyxalar metodi"} />
            <Card text={"Loyxalar metodi"} />
            <Card text={"Loyxalar metodi"} />
            <Card text={"Loyxalar metodi"} />
          </Cards>
        </Box>
      </div>
    </Content>
  );
};

export default MetodsPage;
