import React from "react";
import styled from "styled-components";
import { Flex } from "../Header/Header";
import { Link } from "react-router-dom";
import { Player } from "../../assets/img";
const Content = styled.div`
  padding: 70px 0px 150px 0px;
`;
const Box = styled(Flex)`
  flex-direction: column;
  gap: 60px;
`;
const Title = styled.p`
  color: #242424;
  text-align: right;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Open Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 83.2px */
  letter-spacing: 1.92px;
  text-transform: capitalize;
`;
const Cards = styled(Flex)`
  align-items: flex-start;
  row-gap: 35px;
  column-gap: 40px;
  width: 100%;
  flex-wrap: wrap;
`;
const Card = styled(Flex)`
  width: 410px;
  height: 198px;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid rgb(0, 0, 0);
  background: #fff;
`;
const PlayerImg = styled.img`
  width: 100px;
  height: 100px;
`;
const VideoPage = () => {
  return (
    <Content>
      <div className="container">
        <Box>
          <Title>Metodlar</Title>
          <Cards>
            <Link
              to={"https://youtu.be/jLNrvmXboj8?si=m84cVojQE4oAIX3X"}
              target={"_blank"}
            >
              <Card>
                <PlayerImg src={Player} />
              </Card>
            </Link>
            <Link
              to={"https://youtu.be/jLNrvmXboj8?si=m84cVojQE4oAIX3X"}
              target={"_blank"}
            >
              <Card>
                <PlayerImg src={Player} />
              </Card>
            </Link>
            <Link
              to={"https://youtu.be/jLNrvmXboj8?si=m84cVojQE4oAIX3X"}
              target={"_blank"}
            >
              <Card>
                <PlayerImg src={Player} />
              </Card>
            </Link>
            <Link
              to={"https://youtu.be/jLNrvmXboj8?si=m84cVojQE4oAIX3X"}
              target={"_blank"}
            >
              <Card>
                <PlayerImg src={Player} />
              </Card>
            </Link>
            <Link
              to={"https://youtu.be/jLNrvmXboj8?si=m84cVojQE4oAIX3X"}
              target={"_blank"}
            >
              <Card>
                <PlayerImg src={Player} />
              </Card>
            </Link>
            <Link
              to={"https://youtu.be/jLNrvmXboj8?si=m84cVojQE4oAIX3X"}
              target={"_blank"}
            >
              <Card>
                <PlayerImg src={Player} />
              </Card>
            </Link>
          </Cards>
        </Box>
      </div>
    </Content>
  );
};

export default VideoPage;
