import React from "react";
import Metod from "../../components/Menu/Metod";
import Index from "../../components/Metods/Index";
import Video from "../../components/Videos/Video";
import Hero from "../../components/Header/hero";

const Home = () => {
  return (
    <div>
      {/* <Hero /> */}
      <Metod />
      <Index />
      <Video />
    </div>
  );
};

export default Home;
