import { A2, A5, A6, A7 } from "../../assets/img";
import {
  pdf1,
  pdf2,
  pdf3,
  pdf4,
  pdf5,
  pdf6,
  pdf7,
  pdf8,
  pdf9,
  pdf10,
  pdf11,
  pdf12,
  pdf13,
  pdf14,
  pdf15,
  pdf16,
  pdf17,
  pdf18,
  pdf19,
  pdf20,
  pdf21,
  pdf22,
  pdf23,
  pdf24,
  pdf25,
  pdf26,
} from "../../assets/pdf";

export const menuInfo = [
  {
    id: 1,
    text: "Informatika o'qitish metodikasi fanidan ma'ruzalar",
    img: A5,
    path: "/maruzalar",
    category: "maruza",
  },
  {
    id: 2,
    text: "Informatika fanini o'qitishda yangi metodlar",
    path: "/",
    img: A7,
  },
  {
    id: 3,
    text: "Amaliy topshiriqlar",
    path: "/metods",
    img: A6,
  },
  {
    id: 4,
    text: "Adabiyot va internet havolalar ro'yxati",
    path: "/videos",
    img: A2,
  },
];

export const allMenu = [
  {
    id: 1,
    text: "IAT fanini o'qitishda uslubiyat kursi",
    pdf: pdf1,
    category: "maruza",
  },
  {
    id: 2,
    text: "IAT va ularning o'qitish uslubiyati tarixi",
    pdf: pdf2,
    category: "maruza",
  },
  {
    id: 3,
    text: "IAT o'qitishning uzluksiz tizimi",
    pdf: pdf3,
    category: "maruza",
  },
  {
    id: 4,
    text: "Umumiy tal'lim maktablarida IAT tizimi va mazmuni",
    pdf: pdf4,
    category: "maruza",
  },
  {
    id: 5,
    text: "Umumiy tal'lim maktablarida axborot texnologiyalari o'rni",
    pdf: pdf5,
    category: "maruza",
  },
  {
    id: 6,
    text: "O'quv-metodik ta`minot va ularning turlari",
    pdf: pdf6,
    category: "maruza",
  },
  {
    id: 7,
    text: "IAT bo'yicha o'quv qollanma va didadik materiallar",
    pdf: pdf7,
    category: "maruza",
  },
  {
    id: 8,
    text: "IAT metodik tizimi va kompententligi",
    pdf: pdf8,
    category: "maruza",
  },
  {
    id: 9,
    text: "Umumiy tal'lim maktablarida IAT maqsad va vazifalari",
    pdf: pdf9,
    category: "maruza",
  },
  {
    id: 10,
    text: "Umumiy tal'lim maktablarida IAT shakli va uslublari",
    pdf: pdf10,
    category: "maruza",
  },
  {
    id: 11,
    text: "IAT kursini o'qitishning an`anaviy va interaktiv",
    pdf: pdf11,
    category: "maruza",
  },
  {
    id: 12,
    text: "IAT kursini o'qitish vositalari",
    pdf: pdf12,
    category: "maruza",
  },
  {
    id: 13,
    text: "IAT bo'yicha laboratoriya mashg'ulotlarini maqsad va vazifalari",
    pdf: pdf13,
    category: "maruza",
  },
  {
    id: 14,
    text: "IAT da mustaqil ishlarning mazmuni va o'tkazish metodikas",
    pdf: pdf14,
    category: "maruza",
  },
  {
    id: 15,
    text: "DTS mazmuni",
    pdf: pdf15,
    category: "maruza",
  },
  {
    id: 16,
    text: "Test asosida o'quvchilarning bilimini baxolash",
    pdf: pdf16,
    category: "maruza",
  },
  {
    id: 17,
    text: "Zamonaviy informatika xonasi vazifalari va maqsadi",
    pdf: pdf17,
    category: "maruza",
  },
  {
    id: 18,
    text: "Informatika xonasida Local va Global tarmoq asosdia o'quv-tarbiya ishlarini tashkil etish",
    pdf: pdf18,
    category: "maruza",
  },

  {
    id: 19,
    text: "O'quv jarayonini rejalashtirish tizimi",
    pdf: pdf19,
    category: "maruza",
  },
  {
    id: 20,
    text: "O'quv jarayonini temtik va har bir darsni rejalashtirish",
    pdf: pdf20,
    category: "maruza",
  },
  {
    id: 21,
    text: "IAT fani darsidan tashqari ishlarni tashkillashtirish",
    pdf: pdf21,
    category: "maruza",
  },
  {
    id: 22,
    text: "IAT fanidan olimpiodalar o'tkazish",
    pdf: pdf22,
    category: "maruza",
  },
  {
    id: 23,
    text: "IAT fanidan olimpiodalar masalarini yechish",
    pdf: pdf23,
    category: "maruza",
  },
  {
    id: 24,
    text: "Zamonaviy AT va ularning didaktik vazifalari",
    pdf: pdf24,
    category: "maruza",
  },
  {
    id: 25,
    text: "Informatikani o'qitishning maxsus masalari",
    pdf: pdf25,
    category: "maruza",
  },
  {
    id: 26,
    text: "Informatikani o'qitishda masofaviy texnologiyalari",
    pdf: pdf26,
    category: "maruza",
  },
];
