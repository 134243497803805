import React from "react";
import styled from "styled-components";
import { Flex } from "../Header/Header";
import { Link } from "react-router-dom";
import { Face, Insta, Twit } from "../../assets/img";
const Content = styled.footer`
  padding: 100px 0px 40px 0px;
  background-color: #bb2339;
`;
const Box = styled(Flex)`
  flex-direction: column;
  gap: 80px;
`;
const Top = styled(Flex)`
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;
const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;
const Item = styled.li`
  color: #fff;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  letter-spacing: 0.64px;
`;
const Text = styled.p`
  color: #fff;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Open Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 41.6px */
  letter-spacing: 1.28px;
  text-transform: capitalize;
`;
const Items = styled(Flex)`
  flex-direction: column;
  gap: 10px;
`;

const Social = styled(Flex)`
  gap: 16px;
`;
const Text2 = styled.p`
  color: #fff;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  letter-spacing: 0.64px;
`;
const Footer = () => {
  return (
    // <Content>
    //   <div className="container">
    //     <Box>
    //       <Top>
    //         <List>
    //           <Text>Contact</Text>
    //           <Items>
    //             <Item>9 W 53rd St, London, NY 10019, UK</Item>
    //             <Item>+1 212-344-1230</Item>
    //             <Item>+1 212-555-1230</Item>
    //           </Items>
    //         </List>
    //         <List>
    //           <Text>Logo</Text>
    //           <Items>
    //             <Item
    //               style={{
    //                 width: "438px",
    //                 textAlign: "center",
    //               }}
    //             >
    //               "The best way to find yourself is to lose yourself in the
    //               service of others.”
    //             </Item>
    //           </Items>
    //           <Social>
    //             <Link>
    //               <img src={Face} alt="" />
    //             </Link>
    //             <Link>
    //               <img src={Twit} alt="" />
    //             </Link>
    //             <Link>
    //               <img src={Insta} alt="" />
    //             </Link>
    //           </Social>
    //         </List>
    //         <List>
    //           <Text>Working Hours</Text>
    //           <Items>
    //             <Item
    //               style={{
    //                 width: "170px",
    //                 textAlign: "center",
    //               }}
    //             >
    //               Monday-Friday: 08:00 am -12:00 am
    //             </Item>
    //             <Item
    //               style={{
    //                 width: "200px",
    //                 textAlign: "center",
    //               }}
    //             >
    //               Saturday-Sunday: 07:00am -11:00 pm
    //             </Item>
    //           </Items>
    //         </List>
    //       </Top>
    //       <Text2>2023 Pop online. All Rights reserved.</Text2>
    //     </Box>
    //   </div>
    // </Content>
    <></>
  );
};

export default Footer;
