import React from "react";
import styled from "styled-components";
import { Flex } from "./Header";
import { Profile } from "../../assets/img";
const Content = styled.div`
  padding: 80px 0px 237px 0px;
  background: #bb2339;
`;
const Box = styled(Flex)`
  align-items: center;
  gap: 100px;
  width: 100%;
  justify-content: space-between;
`;
const Texts = styled(Flex)`
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
`;
const Title = styled.h1`
  color: #fff;
  font-family: Open Sans;
  font-size: 90px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 117px */
  letter-spacing: 3.6px;
  text-transform: capitalize;
`;
const Text = styled.p`
  width: 453px;
  color: #fff;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  letter-spacing: 0.64px;
`;
const Img = styled.img``;
const Hero = () => {
  return (
    <Content>
      <div className="container">
        <Box>
          <Texts>
            <Title>salom</Title>
            <Text>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea sit
              reiciendis eligendi blanditiis cumque doloribus similique illum
              deleniti, perferendis perspiciatis ducimus quia dolores veniam,
              itaque sunt excepturi! Recusandae, consectetur enim?
            </Text>
          </Texts>
          <Img src={Profile} />
        </Box>
      </div>
    </Content>
  );
};

export default Hero;
