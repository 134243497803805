import React from "react";
import styled from "styled-components";
import { Flex } from "../Header/Header";
import { Teach } from "../../assets/img";
import { Link } from "react-router-dom";
const Content = styled.div`
  background-color: #ececec;
  padding: 70px 0px 150px 0px;
`;
const Box = styled(Flex)`
  flex-direction: column;
  gap: 60px;
`;
const Title = styled.p`
  color: #242424;
  text-align: right;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Open Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 83.2px */
  letter-spacing: 1.92px;
  text-transform: capitalize;
`;
const Cards = styled(Flex)`
  flex-direction: column;
  gap: 10px;
`;
const Item = styled.div`
  background-color: #fff;
  width: 700px;
  display: flex;
  gap: 50px;
  align-items: center;
  padding: 20px 30px;
  border-radius: 10px;
`;
const Text = styled.p`
  color: #242424;
  text-align: center;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const Img = styled.img`
  width: 60px;
  height: 60px;
`;
const Page = () => {
  var storge = JSON.parse(localStorage.getItem("menu"));
  const [value, setValue] = React.useState(storge);
  function onResumeClick(Pdf) {
    window.open(Pdf);
  }
  return (
    <Content>
      <div className="container">
        <Box>
          <Title>Maruzalar</Title>
          <Cards>
            {value?.map((e) => (
              <Item onClick={() => window.open(e.pdf)}>
                <Img src={Teach} alt="" />
                <Text>{e.text}</Text>
              </Item>
            ))}
          </Cards>
        </Box>
      </div>
    </Content>
  );
};

export default Page;
